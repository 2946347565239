// external
import React from 'react';
import { Skeleton } from '@mui/material';

// components
import { RelationshipItem, RelationshipsEmpty } from 'components';

// types
import { IRelationship } from '@guider-global/shared-types';

interface IRelationshipsListProps {
  relationships: IRelationship[];
  isLoading: boolean;
}

export const RelationshipsList: React.FC<IRelationshipsListProps> = ({
  relationships,
  isLoading,
}) => {
  if (isLoading)
    return (
      <>
        <Skeleton sx={{ height: '45px', mt: 1, transform: 'none' }} />
        <Skeleton sx={{ height: '45px', mt: 1, transform: 'none' }} />
        <Skeleton sx={{ height: '45px', mt: 1, transform: 'none' }} />
      </>
    );

  if (!isLoading && relationships.length === 0) {
    return <RelationshipsEmpty />;
  }

  return (
    <>
      {relationships.map((relationship) => {
        return (
          <RelationshipItem
            key={`relationship-${relationship.id}`}
            relationship={relationship}
          />
        );
      })}
    </>
  );
};
