// external
import {
  Box,
  Divider,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
  useTheme,
  Button as MuiButton,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// components
import { RelationshipSessionsModal } from 'modals';

// Hooks
import { useAvailabilities } from 'hooks';

// types

// Internal
import {
  getFriendlyDateFormat,
  getLanguageFromCMS,
  getTimeFormat,
} from '@guider-global/front-end-utils';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { IRelationship } from '@guider-global/shared-types';
import { ButtonStack, Icon, TextStack, theme } from '@guider-global/ui';
import History from '@mui/icons-material/History';

import { deepMerge } from 'utils';
// types
interface IRelationshipSessionsProps {
  relationship: IRelationship;
  isGroupProgram: boolean;
  isActiveGroup?: boolean;
  isGuide: boolean;
}

export const RelationshipSessions: React.FC<IRelationshipSessionsProps> = ({
  relationship,
  isGroupProgram,
  isActiveGroup = false,
  isGuide,
}) => {
  const { reqAvailabilities } = useAvailabilities({
    getSilently: false,
  });
  const [isSessionsModalOpen, setIsSessionsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const organizationTheme = useTheme();
  const sessions = relationship.sessions;
  const now = new Date();

  const archived = relationship.isConcluded;

  const canSeeNewSessionButton =
    !relationship.isConcluded &&
    ((isGroupProgram && isActiveGroup && isGuide) || !isGroupProgram);

  const canSeeSessions = (isGroupProgram && isActiveGroup) || !isGroupProgram;

  const filteredSessions = sessions?.filter((session) => {
    const sessionEndDate = session?.end ? new Date(session?.end) : undefined;
    if (!sessionEndDate) return false;
    const hasSessionEnded = sessionEndDate <= now;
    if (hasSessionEnded) return false;
    if (session.isArchived) return false;
    return true;
  });

  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  const locale = getLanguageFromCMS(baseLanguage.language_code);

  const FormattedTime = getTimeFormat(baseLanguage.time_format);

  const FormattedDate = getFriendlyDateFormat(baseLanguage.date_format);

  const handleSessionsModalClose = () => {
    navigate(`/relationships/${relationship.id}`);

    setIsSessionsModalOpen(false);
  };

  const handleScheduleSession = async () => {
    await reqAvailabilities({ url: '/availabilities' });

    navigate(`/relationships/${relationship.id}/sessions/create`);
    setIsSessionsModalOpen(true);
  };

  const handleLogSession = async () => {
    navigate(`/relationships/${relationship.id}/sessions/log`);
    setIsSessionsModalOpen(true);
  };
  const handleHistorySession = () => {
    setIsSessionsModalOpen(true);

    navigate(`/relationships/${relationship.id}/sessions/history`);
  };

  const renderSessionsCount = () => {
    const sessionsCount = filteredSessions?.length ?? 0;

    if (sessionsCount > 1) {
      return `${sessionsCount} ${baseLanguage.relationships.sessions.sidebar.multiple_sessions_scheduled_label}`;
    } else if (sessionsCount === 1) {
      return `${sessionsCount} ${baseLanguage.relationships.sessions.sidebar.single_session_scheduled_label}`;
    } else {
      return baseLanguage.relationships.sessions.sidebar
        .no_sessions_scheduled_label;
    }
  };

  const handleSessionClick = (sessionId: string) => {
    navigate(`/relationships/${relationship.id}/sessions/${sessionId}`, {
      replace: false,
    });

    setIsSessionsModalOpen(true);
  };

  useEffect(() => {
    if (pathname.includes('sessions') && !isSessionsModalOpen) {
      setIsSessionsModalOpen(true);
    }
  }, [pathname, isSessionsModalOpen]);

  const declinedStyles: SxProps<Theme> = {
    border: 1,
    borderColor: combinedPalette.error.light,
    backgroundColor: 0,
    textDecoration: 'line-through',
  };
  const tentativeStyles: SxProps<Theme> = {
    border: 1,
    borderColor: combinedPalette.info.light,
    borderStyle: 'dashed',
    backgroundColor: 0,
  };
  const typographyStyles: SxProps<Theme> = {
    textDecoration: 'line-through',
  };

  const renderSessions = () => {
    return filteredSessions?.map((session, index) => {
      const isDeclined =
        session.declinedEmails && session.declinedEmails?.length > 0;

      const isTentative =
        session.tentativeEmails && session.tentativeEmails?.length > 0;

      const sessionStart = session.start ?? '';
      const sessionEnd = session.end ?? '';

      return (
        <MuiButton
          data-cy="components_Relationships_RelationshipSessions_session-button"
          fullWidth
          key={`session-${session.id}`}
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            py: 1,
            px: 1.5,
            textAlign: 'left',
            textTransform: 'none',
            color: combinedPalette.text.primary,
            backgroundColor: '#F5F5F5',
            mt: index === 0 ? 0 : 1.5,
            '&:hover': {
              backgroundColor: '#E0E0E0',
            },
            ...(isDeclined ? declinedStyles : {}),
            ...(isTentative ? tentativeStyles : {}),
          }}
          disabled={archived}
          onClick={() => handleSessionClick(session.id || '')}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, ...(isDeclined ? typographyStyles : {}) }}
          >
            {session.name}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              fontWeight: 500,
              fontSize: '10px',
              ...(isDeclined ? typographyStyles : {}),
            }}
          >
            {format(
              new Date(sessionStart),
              `${FormattedDate}, ${FormattedTime}`,
              {
                locale,
              },
            )}
            {' - '}
            {format(new Date(sessionEnd), FormattedTime, { locale })}
          </Typography>
        </MuiButton>
      );
    });
  };

  return canSeeSessions ? (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <Box
          sx={{
            mt: 3,
            maxHeight: 36,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <TextStack
            size="xs"
            align="left"
            heading={baseLanguage.relationships.sessions.sidebar.title}
            subtitles={[
              {
                variant: 'caption',
                color: 'text.disabled',
                text: renderSessionsCount(),
                sx: { fontWeight: 500 },
              },
            ]}
            sx={{
              fontWeight: 500,
              gap: 1,
              mb: 0.5,
            }}
          />

          <MuiButton
            sx={{
              maxWidth: '24px',
              maxHeight: 24,
              padding: 0,
              color: combinedPalette.text.secondary,
            }}
            size="small"
            onClick={handleHistorySession}
          >
            <Icon Icon={History} size="xs" boxProps={{ padding: 0 }}></Icon>
          </MuiButton>
        </Box>

        <Box sx={{ my: filteredSessions?.length === 0 ? 0.75 : 1.5 }}>
          {renderSessions()}
        </Box>
        {canSeeNewSessionButton && (
          <ButtonStack
            width={'100%'}
            marginTop={1.5}
            marginBottom={1}
            spacing={1}
            direction="column"
            buttons={[
              {
                key: 'new-session',
                variant: 'contained',
                label:
                  baseLanguage.relationships.sessions.sidebar
                    .schedule_session_button_label,
                color: 'info',
                onClick: () => handleScheduleSession(),
              },
              {
                key: 'log-session',
                variant: 'outlined',
                label:
                  baseLanguage.relationships.sessions.sidebar
                    .log_session_button_label,
                color: 'info',
                onClick: () => handleLogSession(),
              },
            ]}
          />
        )}
      </ThemeProvider>
      <Divider sx={{ mt: 3 }} />
      {isSessionsModalOpen && (
        <RelationshipSessionsModal
          relationship={relationship}
          handleClose={handleSessionsModalClose}
          isGroupProgram={isGroupProgram}
          isActiveGroup={isActiveGroup}
          isGuide={isGuide}
        />
      )}
    </>
  ) : undefined;
};

export * from './RelationshipSessionsCreate';
export * from './RelationshipSessionsEdit';
export * from './RelationshipSessionsHistory';
export * from './RelationshipSessionsIntegrate';
export * from './RelationshipSessionsLog';
export * from './RelationshipSessionsView';
