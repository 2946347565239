// external
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import React, { useState } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';

// components
import { ModalCard } from 'components';
import { SessionsEditForm } from 'forms';
import { ConfirmationModal } from 'modals';

// store
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

// hooks
import {
  useMixpanelEvents,
  useProfiles,
  useRelationships,
  useSessions,
} from 'hooks';

// types
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { IProfile, IRelationship, TimeZone } from '@guider-global/shared-types';
import { IButtonAction } from 'components/ActionButton';
import { getVideoConferencingProvider } from 'utils/getVideoConferencingProvider';
import { ISessionInputs } from '../RelationshipSessionsCreate';

interface IRelationshipSessionsEdit {
  relationship: IRelationship;
  handleClose: () => void;
}

export const RelationshipSessionsEdit: React.FC<IRelationshipSessionsEdit> = ({
  relationship,
  handleClose,
}) => {
  //state
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  //form
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm<ISessionInputs>({ mode: 'onChange' });
  const typedControl: Control = control as unknown as Control;
  const programSlug: string = relationship.programSlug as string;

  //hooks
  const { trackMixpanelEvent, trackScreenTime } = useMixpanelEvents();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organizationSlug = getSubDomain();

  //baseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const cancelButtonLabel =
    baseLanguage.globals.common.cancel_button_label ?? 'Cancel';

  //sessions
  const { isLoadingSessions, reqSessions, sessions } = useSessions({});
  const { reqRelationships } = useRelationships({ getSilently: false });

  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const { sessionId } = useParams();
  const session = sessions().find((session) => session.id === sessionId);

  if (!session?.createdAt) {
    return <></>;
  }

  const isSessionOwner = profile.id === (session.ownerProfile as IProfile).id;

  //handlers

  const navigateBack = () => {
    navigate(`/relationships/${relationship.id}/sessions/${sessionId}`);
  };

  const onSubmit: SubmitHandler<ISessionInputs> = async (data) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions() as {
      timeZone: TimeZone;
    };

    const traineeProfiles = relationship.traineeProfiles ?? [];
    const guideProfile = relationship.guideProfiles ?? [];

    const { date, startTime, endTime, videoConferencing, location } = data;

    const [startHours, startMinutes] = startTime.split(':');
    const newStart = toDate(date);
    newStart.setHours(parseInt(startHours), parseInt(startMinutes));

    const [endHours, endMinutes] = endTime.split(':');
    const newEnd = toDate(date);
    newEnd.setHours(parseInt(endHours), parseInt(endMinutes));

    const startUTC = zonedTimeToUtc(newStart, timeZone);
    const endUTC = zonedTimeToUtc(newEnd, timeZone);

    await reqSessions({
      method: 'PATCH',
      url: `/sessions/${sessionId}`,
      data: {
        name: data.title,
        organizationSlug,
        programSlug,
        relationshipId: relationship.id,
        description: '',
        participantProfiles: [
          ...traineeProfiles.map((trainee) => trainee.id ?? ''),
          ...guideProfile.map((guide) => guide.id ?? ''),
        ],
        start: startUTC,
        end: endUTC,
        timezone: timeZone,
        hasVideoConferencing: Boolean(videoConferencing),
        videoConferencing: videoConferencing,
        ownerProfile: profile.id,
        location,
      },
    });

    trackScreenTime('Relationship - Session - Session updated', {
      'Updated by': isSessionOwner ? 'Organizer' : 'Participant',
      'Video conferencing': Boolean(videoConferencing),
      'Video conferencing provider':
        getVideoConferencingProvider(videoConferencing),
      Location: Boolean(location),
    });

    await Promise.all([
      reqSessions({ url: '/sessions' }),
      reqRelationships({ url: '/relationships' }),
    ]);

    dispatch(
      showAppAlert({
        severity: 'success',
        message:
          baseLanguage.relationships.sessions.edit_session_modal
            .success_alert_text,
        timeout: 5000,
      }),
    );

    navigateBack();
  };

  const handleDiscardChanges = () => {
    if (!isDirty) {
      navigateBack();
    } else {
      setIsDiscardModalOpen(true);
    }
  };

  const handleDeleteSession = async () => {
    await reqSessions({
      method: 'PATCH',
      url: `/sessions/${sessionId}`,
      data: {
        isArchived: true,
      },
    });

    trackMixpanelEvent('Relationship - Session - Session deleted', {
      'Deleted by': isSessionOwner ? 'Organizer' : 'Participant',
    });

    await Promise.all([
      reqSessions({ url: '/sessions' }),
      reqRelationships({ url: '/relationships' }),
    ]);

    dispatch(
      showAppAlert({
        severity: 'success',
        message:
          baseLanguage.relationships.sessions.confirm_session_deletion_modal
            .success_alert_text,
        timeout: 5000,
      }),
    );

    handleClose();

    navigate(`/relationships/${relationship.id}`);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDiscardModalClose = () => {
    setIsDiscardModalOpen(false);
  };

  //modal actions

  const contentModalActions: (IButtonAction | IButtonAction[])[] = [
    {
      label: baseLanguage.globals.common.delete_button_label,
      variant: 'text',
      color: 'error',
      action: () => setIsDeleteModalOpen(true),
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsEdit_delete-button',
    },
    [
      {
        label: cancelButtonLabel,
        variant: 'outlined',
        color: 'info',
        action: handleDiscardChanges,
        dataCyLabel:
          'components_Relationships_RelationshipSessions_RelationshipSessionsEdit_discard-button',
      },
      {
        label:
          baseLanguage.relationships.sessions.edit_session_modal
            .update_calendar_invite_button_label,
        variant: 'contained',
        color: 'info',
        isLoadingButton: true,
        action: handleSubmit(onSubmit),
        dataCyLabel:
          'components_Relationships_RelationshipSessions_RelationshipSessionsEdit_submit-button',
      },
    ],
  ];
  const discardModalActions: IButtonAction[] = [
    {
      label: cancelButtonLabel,
      action: handleDiscardModalClose,
      color: 'info',
      variant: 'outlined',
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsEdit_close-discard-button',
    },
    {
      label: baseLanguage.globals.common.discard_changes_button_label,
      action: () => {
        navigateBack();
      },
      color: 'error',
      variant: 'contained',
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsEdit_confirm-discard-button',
    },
  ];
  const deleteModalActions: IButtonAction[] = [
    {
      label: cancelButtonLabel,
      action: handleDeleteModalClose,
      color: 'info',
      variant: 'outlined',
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsEdit_close-delete-button',
    },
    {
      label: baseLanguage.globals.common.delete_button_label,
      action: () => {
        handleDeleteSession();
      },
      color: 'error',
      variant: 'contained',
      isLoadingButton: true,
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsEdit_confirm-delete-button',
    },
  ];

  return (
    <ModalCard
      title={baseLanguage.relationships.sessions.edit_session_modal.title}
      description={
        baseLanguage.relationships.sessions.edit_session_modal.subtitle
      }
      handleClose={handleClose}
      isLoading={isLoadingSessions()}
      actions={contentModalActions}
    >
      <SessionsEditForm
        session={session}
        handleSubmit={handleSubmit(onSubmit)}
        control={typedControl}
        errors={errors}
        isValid={isValid}
        isDirty={isDirty}
        isLoadingSessions={isLoadingSessions()}
        discardAction={navigateBack}
        setValue={setValue}
        hideButtons
      />
      <ConfirmationModal
        open={isDiscardModalOpen}
        title={baseLanguage.goals.discard_goal_modal.title}
        description={baseLanguage.goals.discard_goal_modal.description}
        actions={discardModalActions}
        handleClose={handleDiscardModalClose}
      />
      <ConfirmationModal
        open={isDeleteModalOpen}
        title={
          baseLanguage.relationships.sessions.confirm_session_deletion_modal
            .title
        }
        description={
          baseLanguage.relationships.sessions.confirm_session_deletion_modal
            .description
        }
        actions={deleteModalActions}
        handleClose={handleDeleteModalClose}
        isLoading={isLoadingSessions()}
      />
    </ModalCard>
  );
};
