// external
import React from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';
import { GoalsCreateForm } from 'forms';
import { useGoals, useRelationships } from 'hooks';

// types
import { IGoal, IRelationship } from '@guider-global/shared-types';

import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';

interface IRelationshipGoalsCreateProps {
  relationship: IRelationship;
  handleClose: () => void;
}

export type IGoalInputs = {
  categorySlug: string;
  objective: string;
  outcomes: string;
  rationale: string;
};

export const RelationshipGoalsCreate: React.FC<
  IRelationshipGoalsCreateProps
> = ({ relationship, handleClose }) => {
  //baseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  //form
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<IGoalInputs>({ mode: 'onChange' });
  const typedControl: Control = control as unknown as Control;

  //hooks
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { isLoadingGoals, reqGoals } = useGoals({});
  const { reqRelationships } = useRelationships({});

  //handlers
  const onSubmit: SubmitHandler<IGoalInputs> = async (data) => {
    Object.assign(data, {
      status: 'on_track',
      organizationSlug,
      relationshipId: relationship.id,
    });

    const { data: goalsResponseData } = await reqGoals({
      method: 'POST',
      url: '/goals',
      data,
    });

    const currentGoalIds = relationship.goals?.map((goal) => goal.id) ?? [];

    const newGoalIds = goalsResponseData?.map((goal: IGoal) => goal.id) ?? [];

    await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationship.id}`,
      // TODO typing needs fixing
      // @ts-ignore
      data: {
        goals: [...currentGoalIds, ...newGoalIds],
      },
    });

    await Promise.all([
      reqGoals({ url: '/goals' }),
      reqRelationships({ url: '/relationships' }),
    ]);

    navigate(`/relationships/${relationship.id}/goals`);
  };

  //modal actions
  const contentModalActions: IButtonAction | IButtonAction[] = [
    {
      label:
        baseLanguage.goals.create_and_update_goal.create_goal
          .discard_button_label,
      variant: 'outlined',
      color: 'info',
      action: () => navigate(-1),
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsCreate_navigation-button',
    },
    {
      label: baseLanguage.globals.goals.create_goal_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !isValid,
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsCreate_submit-button',
    },
  ];

  return (
    <ModalCard
      title={baseLanguage.goals.create_and_update_goal.create_goal.title}
      handleClose={handleClose}
      actions={contentModalActions}
      isLoading={isLoadingGoals()}
    >
      <GoalsCreateForm
        handleSubmit={handleSubmit(onSubmit)}
        control={typedControl}
        errors={errors}
        isValid={isValid}
        isLoadingGoals={isLoadingGoals()}
        discardAction={() => navigate(-1)}
        hideButtons
      />
    </ModalCard>
  );
};
