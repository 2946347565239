// external
import { Divider, Drawer, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import {
  RelationshipGoals,
  RelationshipNotes,
  RelationshipSessions,
  RelationshipViewProfile,
} from 'components';

// types
import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';
import { IRelationship } from '@guider-global/shared-types';

import { useMobileMediaQuery } from 'hooks';

interface IRelationshipDetailsSidebarProps {
  relationship: IRelationship;
  isGroupProgram: boolean;
  isGuide: boolean;
}

export const RelationshipDetailsSidebar: React.FC<
  IRelationshipDetailsSidebarProps
> = ({ relationship, isGroupProgram, isGuide }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const programSlug = relationship.programSlug;
  const { getProgram, isLoadingSanityPrograms } = useSanityOrganizationPrograms(
    {},
  );
  const loadingPrograms = isLoadingSanityPrograms();
  const program = getProgram(programSlug);

  useEffect(() => {
    if (!program && !loadingPrograms) {
      navigate('/relationships');
    }
  }, [loadingPrograms, navigate, program]);

  if (!program) {
    return <></>;
  }

  const programName = program.metadata.program_name;
  return (
    <Drawer
      data-cy="components_relationship_drawer"
      open={true}
      anchor="right"
      variant="permanent"
      sx={{
        height: '100%',
        '& .MuiDrawer-paper': {
          height: '100%',
          overflowY: 'auto',
          width: isMobile ? '100vw' : '320px',
          borderLeft: `1px solid ${theme.palette.divider}`,
          position: isMobile ? 'absolute' : 'static',
          p: 3,
        },
      }}
    >
      {isMobile && (
        <>
          <Typography variant="body2" sx={{ py: 2 }}>
            {programName}
          </Typography>
          <Divider sx={{ mb: 5 }} />
        </>
      )}
      <RelationshipViewProfile relationship={relationship} />
      <RelationshipGoals relationship={relationship} isGuide={isGuide} />
      <RelationshipSessions
        relationship={relationship}
        isGroupProgram={isGroupProgram}
        isGuide={isGuide}
      />
      <RelationshipNotes relationship={relationship} />
    </Drawer>
  );
};

export default RelationshipDetailsSidebar;
