// external
import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { IAttendee, SessionsDetails, ModalCard } from 'components';

// Hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

// types
import { getISOStringWithoutMilliseconds } from '@guider-global/front-end-utils';
import { IRelationship, ISession } from '@guider-global/shared-types';
import { IButtonAction } from 'components/ActionButton';
import { useMixpanelEvents } from 'hooks';

interface IRelationshipSessionsViewProps {
  relationship: IRelationship;
  handleClose: () => void;
  isGroupProgram: boolean;
  isActiveGroup: boolean;
  isGuide: boolean;
}

export const RelationshipSessionsView: React.FC<
  IRelationshipSessionsViewProps
> = ({ relationship, handleClose, isGroupProgram, isActiveGroup, isGuide }) => {
  const canSeeEditSessionButton =
    !relationship.isConcluded &&
    ((isGroupProgram && isActiveGroup && isGuide) || !isGroupProgram);
  //base language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  //hooks
  const { trackMixpanelEvent } = useMixpanelEvents();
  const navigate = useNavigate();

  //sessions
  const { sessionId } = useParams();
  const relationshipSessions = relationship?.sessions as ISession[];
  const session = relationshipSessions.find(
    (session) => session.id === sessionId,
  );

  useEffect(() => {
    if (!session) return;
    trackMixpanelEvent('Relationship - Session - Session viewed', {
      Viewed: getISOStringWithoutMilliseconds(),
    });
  }, [session, trackMixpanelEvent]);

  if (!session) {
    return <></>;
  }

  //handlers
  const handleEditSession = () => {
    navigate(`/relationships/${relationship.id}/sessions/${sessionId}/edit`);
  };

  //modal actions
  const modalActions: (IButtonAction | IButtonAction[])[] = [
    {
      label: baseLanguage.globals.common.close_button_label,
      variant: 'contained',
      color: 'info',
      action: handleClose,
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsView_close-button',
    },
  ];

  if (canSeeEditSessionButton) {
    modalActions.unshift({
      label: baseLanguage.globals.sessions.edit_session_button_label,
      variant: 'outlined',
      color: 'info',
      action: handleEditSession,
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsView_edit-session-button',
    });
  }

  const attendees: IAttendee[] = [
    ...(relationship.guideProfiles ?? []),
    ...(relationship.traineeProfiles ?? []),
  ].map((profile) => {
    const user = relationship.users?.find((user) => user.id === profile.userId);
    return {
      ...profile,
      userPicture: user?.picture,
    };
  });

  return (
    <ModalCard
      title={baseLanguage.relationships.sessions.view_session_modal.title}
      actions={modalActions}
      handleClose={handleClose}
      width={'450px'}
    >
      <Card
        variant="outlined"
        sx={{ backgroundColor: '#FAFAFA', widht: '100%', p: 2 }}
      >
        {<SessionsDetails session={session} attendees={attendees} />}
      </Card>
    </ModalCard>
  );
};
