// external
import { LoadingButton } from '@mui/lab';
import { Box, ThemeProvider, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//internal

// components
import { TextStack, theme } from '@guider-global/ui';
import {
  RelationshipDiscardNoteChangesModal,
  RelationshipNotesModal,
  RelationshipNotesModalProvider,
} from 'modals';
//hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

//types
import { IRelationship } from '@guider-global/shared-types';
import { deepMerge } from 'utils';

interface IRelationshipNotesProps {
  relationship: IRelationship;
}

export const RelationshipNotes: React.FC<IRelationshipNotesProps> = ({
  relationship,
}) => {
  //state
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  //style

  //hooks
  const navigate = useNavigate();

  const { getBaseLanguage } = useSanityBaseLanguage({});

  const baseLanguage = getBaseLanguage();
  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  //handlers

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false);

    navigate(`/relationships/${relationship.id}`);
  };

  const handleViewNotes = () => {
    setIsNotesModalOpen(true);

    navigate(`/relationships/${relationship.id}/notes`);
  };

  const handleCreateNote = () => {
    setIsNotesModalOpen(true);

    navigate(`/relationships/${relationship.id}/notes/create`);
  };

  return (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <TextStack
          size="xs"
          align="left"
          heading={
            baseLanguage.relationships.notes.note_sidebar.note_sidebar_title
          }
          sx={{
            fontWeight: 500,
            mt: 3,
          }}
        />
        <Typography
          variant="caption"
          sx={{ fontWeight: 500 }}
          color="text.disabled"
        >
          {
            baseLanguage.relationships.notes.note_sidebar
              .note_sidebar_description
          }
        </Typography>
        <Box
          onClick={handleCreateNote}
          sx={{
            mt: 3,
            my: 1.5,
            width: '100%',
            minHeight: '83px',
            borderRadius: '5px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            transition: 'ease-in-out 0.2s',
            ':hover': {
              border: '1px solid rgba(0, 0, 0, 1)',
              cursor: 'text',
            },
          }}
        >
          <Typography
            sx={{ pt: 1.5, px: 2 }}
            variant="body2"
            color="text.disabled"
          >
            {
              baseLanguage.relationships.notes.note_sidebar
                .note_sidebar_input_label
            }
          </Typography>
        </Box>

        <LoadingButton
          data-cy="components_Relationships_RelationshipNotes_view-notes-button"
          fullWidth
          variant="outlined"
          color="info"
          sx={{
            fontWeight: 600,
            mb: 2,
            px: 0.5,
            py: 1,
          }}
          onClick={handleViewNotes}
        >
          {
            baseLanguage.relationships.notes.note_sidebar
              .note_sidebar_view_button_label
          }
        </LoadingButton>
      </ThemeProvider>
      <RelationshipNotesModalProvider>
        <RelationshipNotesModal
          relationship={relationship}
          isOpen={isNotesModalOpen}
          handleClose={handleNotesModalClose}
        />
        <RelationshipDiscardNoteChangesModal />
      </RelationshipNotesModalProvider>
    </>
  );
};

export * from './RelationshipNotesCreate';
export * from './RelationshipNotesEmpty';
export * from './RelationshipNotesList';
export * from './RelationshipNotesView';
