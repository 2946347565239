// external
import { TimelineDot } from '@mui/lab';
import {
  Box,
  Divider,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { RelationshipGoalsModal } from 'modals';

// Hooks
import {
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';

// types
import { IGoal, IRelationship } from '@guider-global/shared-types';
import { Button, Stack, TextStack, theme } from '@guider-global/ui';
import { deepMerge } from 'utils';
import { getSubDomain } from '@guider-global/front-end-utils';
interface IRelationshipGoalsProps {
  relationship: IRelationship;
  isGuide: boolean;
}

export const RelationshipGoals: React.FC<IRelationshipGoalsProps> = ({
  relationship,
  isGuide,
}) => {
  const [isGoalsModalOpen, setIsGoalsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { getBaseLanguage } = useSanityBaseLanguage({});

  const baseLanguage = getBaseLanguage();
  const subdomain = getSubDomain();

  const goals = relationship?.goals as Partial<IGoal>[];
  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );
  const archived = relationship.isConcluded;

  const { getOrganization } = useSanityOrganization({
    getSilently: true,
    organizationSlug: subdomain,
    localeCode: 'en_GB',
  });

  const organization = getOrganization();

  const goalCategories = organization.goal_categories.goal_categories.map(
    (goalCategory) => ({
      label: goalCategory.goal_category_name,
      slug: goalCategory.goal_category_slug.current,
    }),
  );

  const handleGoalsModalClose = () => {
    navigate(`/relationships/${relationship.id}`);

    setIsGoalsModalOpen(false);
  };

  const handleManageGoals = () => {
    navigate(`/relationships/${relationship.id}/goals`);

    setIsGoalsModalOpen(true);
  };

  const handleGoalClick = (goalId: string) => {
    navigate(`/relationships/${relationship.id}/goals/${goalId}`, {
      replace: false,
    });

    setIsGoalsModalOpen(true);
  };

  const renderGoals = () => {
    return goals.map((goal) => {
      let palette: 'success' | 'warning' | 'error' | 'info' = 'info';

      if (goal.status === 'off_track') {
        palette = 'warning';
      }

      if (goal.status === 'on_hold') {
        palette = 'error';
      }

      if (goal.status === 'completed') {
        palette = 'success';
      }

      const goalCategory = goalCategories.find(
        (goalCategory) => goalCategory.slug === goal.categorySlug,
      );

      return (
        <Button
          data-cy="components_Relationships_RelationshipGoals_relationship-goals-button"
          fullWidth
          key={`goal-${goal.id}`}
          variant="text"
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: combinedPalette.text.primary,
          }}
          onClick={() => handleGoalClick(goal.id || '')}
          disabled={archived}
        >
          <Stack
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <TimelineDot
              color={palette}
              sx={{
                mr: 2,
                boxShadow: 'none',
              }}
            />
            <Typography variant="body2" textAlign={'left'}>
              {`${goalCategory?.label ?? goalCategory?.slug} ${
                goal.objective ? ' - ' + goal.objective : ''
              }`}
            </Typography>
          </Stack>
        </Button>
      );
    });
  };

  return (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <TextStack
          size="xs"
          align="left"
          heading={baseLanguage.relationships.goals.sidebar.title}
          subtitles={[
            {
              variant: 'caption',
              color: 'text.disabled',
              text: baseLanguage.relationships.goals.sidebar
                .no_goals_shared_label,
              sx: { fontWeight: 500 },
              display: goals.length === 0 ? '' : 'none',
            },
          ]}
          sx={{
            fontWeight: 500,
            mt: 3,
            gap: 1,
            mb: goals.length === 0 ? 1 : 0,
          }}
        />
        <Box sx={{ mb: 1 }}>{renderGoals()}</Box>
        {!isGuide && (
          <Button
            daty-cy="components_relationship_relationshipGoals_manageGoals_button"
            fullWidth
            variant="outlined"
            disabled={archived}
            color="info"
            sx={{
              mb: 2,
              px: 0.5,
              py: 1,
            }}
            onClick={handleManageGoals}
          >
            {baseLanguage.relationships.goals.sidebar.manage_goals_button_label}
          </Button>
        )}
      </ThemeProvider>
      <Divider sx={{ mt: 2 }} />
      <RelationshipGoalsModal
        relationship={relationship}
        isOpen={isGoalsModalOpen}
        handleClose={handleGoalsModalClose}
        isGuide={isGuide}
      />
    </>
  );
};

export * from './RelationshipGoalsCreate';
export * from './RelationshipGoalsEdit';
export * from './RelationshipGoalsManage';
export * from './RelationshipGoalsView';
