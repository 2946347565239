// external
import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { GoalsEmptyPageContent, GoalsItem, ModalCard } from 'components';

// hooks
import { useGoals, useRelationships } from 'hooks';

// types
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { IGoal, IRelationship } from '@guider-global/shared-types';
import { Divider } from '@guider-global/ui';
import { IButtonAction } from 'components/ActionButton';

interface IRelationshipGoalsManageProps {
  relationship: IRelationship;
  handleClose: () => void;
}

export const RelationshipGoalsManage: React.FC<
  IRelationshipGoalsManageProps
> = ({ relationship, handleClose }) => {
  const { relationshipId = '' } = useParams<{
    relationshipId: string;
  }>();

  //style
  const theme = useTheme();

  //baseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  //hooks

  const { reqRelationships, isLoadingRelationships } = useRelationships({});
  const navigate = useNavigate();

  const { goals, reqGoals, isLoadingGoals } = useGoals({
    getSilently: true,
  });

  // Get goals data

  const goalsResult = goals();

  const relationshipGoals = goalsResult.filter(
    (goal) => goal.relationshipId === relationshipId,
  );

  const generalGoals = goalsResult?.filter(
    (goal) => !goal.relationshipId && goal.status !== 'completed',
  );

  //  Get goal ids

  const currentRelationshipGoalIds = relationshipGoals.map(
    (goal: IGoal) => goal.id,
  );

  if (!relationship) {
    return <></>;
  }

  //handlers
  const handleLinkGoal = async (id: string) => {
    const { data: goalsResponseData } = await reqGoals({
      method: 'PATCH',
      url: `/goals/${id}`,
      data: {
        relationshipId,
      },
    });
    reqGoals({ url: '/goals' });

    const newGoalIds = goalsResponseData?.map((goal: IGoal) => goal.id) ?? [];

    await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationshipId}`,
      // TODO Fix typings
      // @ts-ignore
      data: {
        goals: [...currentRelationshipGoalIds, ...newGoalIds],
      },
    });
    reqRelationships({ url: '/relationships' });
  };

  //modal actions
  const modalActions: (IButtonAction | IButtonAction[])[] = [
    {
      label: baseLanguage.globals.common.close_button_label,
      color: 'info',
      variant: 'contained',
      action: handleClose,
      isLoadingButton: true,
    },
  ];

  //renderers
  const renderContent = () => {
    if (relationshipGoals.length === 0 && generalGoals.length === 0) {
      return (
        <GoalsEmptyPageContent
          createPath={`/relationships/${relationship.id}/goals/create`}
        />
      );
    }

    return (
      <>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            pb: 2,
          }}
        >
          <Button
            data-cy="components_Relationships_RelationshipGoals_RelationshipGoalsManage_manage-button"
            startIcon={<MdAdd />}
            onClick={() =>
              navigate(`/relationships/${relationship.id}/goals/create`)
            }
            color="info"
            variant="outlined"
            sx={{ fontWeight: 600 }}
          >
            {baseLanguage.globals.goals.create_goal_button_label}
          </Button>
        </Box>

        {relationshipGoals.length !== 0 && generalGoals.length !== 0 && (
          <Divider sx={{ pb: 2 }}>
            <Typography variant="subtitle2" color={'text.secondary'}>
              {baseLanguage?.relationships?.goals?.manage_goals_modal
                ?.relationship_goals_divider_text ?? 'Relationship goals'}
            </Typography>
          </Divider>
        )}
        {relationshipGoals.length !== 0 &&
          relationshipGoals.map((goal) => (
            <GoalsItem
              key={`relationship-goals-${goal.id}`}
              goal={goal}
              sx={{ mb: 2 }}
              viewPath={`/relationships/${relationship.id}/goals/${goal.id}`}
            />
          ))}
        {generalGoals.length !== 0 && (
          <>
            <Divider sx={{ pb: 2 }}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                {baseLanguage?.relationships?.goals?.manage_goals_modal
                  ?.general_goals_divider_text ?? 'General goals'}
              </Typography>
            </Divider>
            {generalGoals.map((goal) => (
              <GoalsItem
                key={`general-goals-${goal.id}`}
                linkGoalVariant
                handleLinkGoal={() => handleLinkGoal(goal.id)}
                goal={goal}
                sx={{ mb: 2 }}
                viewPath={`/relationships/${relationship.id}/goals/${goal.id}`}
              />
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <ModalCard
      title={baseLanguage.relationships.goals.manage_goals_modal.title}
      description={baseLanguage.relationships.goals.manage_goals_modal.subtitle}
      handleClose={handleClose}
      actions={modalActions}
      isLoading={isLoadingRelationships() || isLoadingGoals()}
      childrenSx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        overflowY: 'auto',
        p: 2,
      }}
    >
      {renderContent()}
    </ModalCard>
  );
};
