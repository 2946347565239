import { IProfile, IRelationship } from '@guider-global/shared-types';
import { Box } from '@mui/material';
import { useMixpanelEvents, useProfiles, useRelationships } from 'hooks';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getISOStringWithoutMilliseconds } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { AvatarButton, DropDown, IconButton } from '@guider-global/ui';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import PersonOff from '@mui/icons-material/PersonOff';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import RelationshipsConcludeDialog from './RelationshipsConcludeDialog';

interface RelationshipConcludeProps {
  relationship: IRelationship;
}

const RelationshipConclude: FC<RelationshipConcludeProps> = ({
  relationship,
}) => {
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const profileId = profile?.id;

  const programSlug = relationship.programSlug;
  const relationshipId = relationship.id;

  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const navigate = useNavigate();
  // Local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Redux
  const dispatch = useAppDispatch();

  // Hooks
  const { trackMixpanelEvent } = useMixpanelEvents();
  const guideProfiles = relationship?.guideProfiles as IProfile[];
  const guideProfilesIds = guideProfiles?.map((guide) => guide.id) ?? [];
  const isGroupProgram = relationship?.programVariationTypeSlug === 'group';
  const isGuide = guideProfilesIds.includes(profileId);

  const programType = program?.program_details?.program_type;
  const concludeRelationship =
    programType?.program_type_text.variations?.individual?.relationships
      ?.conclude_relationship;

  const cancelButtonLabel =
    baseLanguage.globals?.common?.cancel_button_label ?? 'Cancel';

  // Conclude Relationship - Individual
  const concludeIndividualRelationshipButtonLabel =
    concludeRelationship?.button_title;

  const concludeRelationshipDescription =
    concludeRelationship?.button_description;

  const dialogIndividualTitle =
    concludeRelationship?.conclude_relationship_modal.title;

  const dialogIndividualDescription =
    concludeRelationship?.conclude_relationship_modal.description;

  // Conclude Group
  const concludeGroupButtonLabel =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.conclude_relationship.button_title;

  const concludeGroupButtonDescription =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.conclude_relationship.button_description;

  const dialogGroupGuideDescription =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.conclude_relationship.conclude_relationship_modal.description;

  const dialogGroupGuideTitle =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.conclude_relationship.conclude_relationship_modal.title;

  const cancelButtonLabelGroupGuide =
    baseLanguage.globals?.common?.cancel_button_label ?? 'Cancel';

  // Leave Group
  const leaveGroupButtonLabel =
    programType?.program_type_text.variations?.group?.relationships?.trainee
      ?.conclude_relationship.button_title;
  const leaveGroupButtonDescription =
    programType?.program_type_text.variations?.group?.relationships?.trainee
      ?.conclude_relationship.button_description;

  const dialogGroupTraineeDescription =
    programType?.program_type_text.variations?.group?.relationships?.trainee
      ?.conclude_relationship.conclude_relationship_modal.description;

  const dialogGroupTraineeTitle =
    programType?.program_type_text.variations?.group?.relationships?.trainee
      ?.conclude_relationship.conclude_relationship_modal.title;

  const cancelButtonLabelGroupTrainee =
    baseLanguage.globals?.common?.cancel_button_label ?? 'Cancel';

  // Success alerts
  const successTextIndividual =
    concludeRelationship?.conclude_relationship_modal.success_alert_text;

  const successTextGroupGuide =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.conclude_relationship.conclude_relationship_modal.success_alert_text;

  const successTextGroupTrainee =
    programType?.program_type_text.variations?.group?.relationships?.trainee
      ?.conclude_relationship.conclude_relationship_modal.success_alert_text;

  const getSuccess = () => {
    if (!isGroupProgram) return successTextIndividual ?? '';
    if (isGuide) return successTextGroupGuide ?? '';
    return successTextGroupTrainee ?? '';
  };
  const success = getSuccess();

  // Error alerts
  const errorTextIndividual =
    concludeRelationship?.conclude_relationship_modal.error_alert_text;

  const errorTextGroupGuide =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.conclude_relationship.conclude_relationship_modal.error_alert_text;

  const errorTextGroupTrainee =
    programType?.program_type_text.variations?.group?.relationships?.trainee
      ?.conclude_relationship.conclude_relationship_modal.error_alert_text;

  const getError = () => {
    if (!isGroupProgram) return errorTextIndividual ?? '';
    if (isGuide) return errorTextGroupGuide ?? '';
    return errorTextGroupTrainee ?? '';
  };
  const error = getError();

  // Relationships
  const { reqRelationships } = useRelationships({ getSilently: false });

  // Events
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleConcludeRelationship = async () => {
    let isSuccess = false;

    setIsLoading(true);

    if ((isGroupProgram && isGuide) || !isGroupProgram) {
      const updateRelationship = await reqRelationships({
        method: 'PATCH',
        url: `/relationships/${relationshipId}`,
        data: {
          isConcluded: true,
        },
      });

      isSuccess = updateRelationship.status === 'success';

      if (isSuccess) {
        trackMixpanelEvent('Relationship - Concluded', {
          'Concluded on': getISOStringWithoutMilliseconds(Date.now()),
          Program: programSlug,
          'Concluded by': isGuide ? 'guide' : 'trainee',
        });
      }
    } else {
      const updateRelationship = await reqRelationships({
        method: 'PATCH',
        url: `/relationships/${relationshipId}`,
        // TODO FIX types
        // @ts-ignore
        data: {
          traineeProfiles: [profileId],
        },
      });

      isSuccess = updateRelationship.status === 'success';
    }

    dispatch(
      showAppAlert({
        severity: isSuccess ? 'success' : 'error',
        message: isSuccess ? success : error,
        timeout: 5000,
      }),
    );

    setIsModalOpen(false);

    await Promise.all([reqRelationships({ url: '/relationships' })]);

    navigate('/relationships');

    setIsLoading(false);
  };

  // Derivatives
  const archived = relationship.isConcluded;

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <DropDown
          sx={{ ul: { p: 0 } }}
          anchor={<IconButton variant="regular" icon={<MoreHoriz />} />}
          children={
            <AvatarButton
              sx={{ px: 1, py: 0 }}
              onClick={handleModalOpen}
              orientation="horizontal"
              icon={<PersonOff />}
              size="small"
              heading={
                !isGroupProgram
                  ? concludeIndividualRelationshipButtonLabel
                  : isGuide
                  ? concludeGroupButtonLabel
                  : leaveGroupButtonLabel
              }
              subtitles={[
                !isGroupProgram
                  ? concludeRelationshipDescription || ''
                  : isGuide
                  ? concludeGroupButtonDescription || ''
                  : leaveGroupButtonDescription || '',
              ]}
              disabled={archived}
            />
          }
        ></DropDown>

        <RelationshipsConcludeDialog
          dialogTitle={
            !isGroupProgram
              ? dialogIndividualTitle || ''
              : isGuide
              ? dialogGroupGuideTitle || ''
              : dialogGroupTraineeTitle || ''
          }
          dialogDescription={
            !isGroupProgram
              ? dialogIndividualDescription || ''
              : isGuide
              ? dialogGroupGuideDescription || ''
              : dialogGroupTraineeDescription || ''
          }
          cancelButtonLabel={
            !isGroupProgram
              ? cancelButtonLabel || ''
              : isGuide
              ? cancelButtonLabelGroupGuide || ''
              : cancelButtonLabelGroupTrainee || ''
          }
          concludeRelationshipButtonLabel={
            !isGroupProgram
              ? concludeIndividualRelationshipButtonLabel || ''
              : isGuide
              ? concludeGroupButtonLabel || ''
              : leaveGroupButtonLabel || ''
          }
          open={isModalOpen}
          onClose={handleModalClose}
          onConcludeRelationship={handleConcludeRelationship}
          loading={isLoading}
        />
      </Box>
    </>
  );
};

export default RelationshipConclude;
