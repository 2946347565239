// external
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';

// components

// store
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

// hooks
import { useProfiles, useRelationships, useSessions } from 'hooks';

// types
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  IRelationship,
  TimeZone,
} from '@guider-global/shared-types';
import { Checkbox, Stack, theme } from '@guider-global/ui';
import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';
import { add, sub } from 'date-fns';
import { deepMerge } from 'utils';

export interface ISessionLogInputs {
  title: string;
  date: Date;
  startTime: string;
  endTime: string;
}

interface IRelationshipSessionsLogProps {
  relationship: IRelationship;
  handleClose: () => void;
}

type TDateCheckboxName = '0' | '1' | '7' | '28';

interface IDateCheckbox {
  heading: string;
  name: TDateCheckboxName;
  pastDate: Date;
}

export const RelationshipSessionsLog: React.FC<
  IRelationshipSessionsLogProps
> = ({ relationship, handleClose }) => {
  const [searchParams] = useSearchParams();
  const day = (searchParams.get('day') ?? '0') as TDateCheckboxName | undefined;

  const [checkedDate, setCheckedDate] = useState<TDateCheckboxName | undefined>(
    day,
  );

  //baseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const pastSessionsLabels =
    baseLanguage.relationships.sessions.log_past_session_modal;

  const checkboxes: IDateCheckbox[] = [
    {
      heading: pastSessionsLabels.today_checkbox_label,
      name: '0',
      pastDate: sub(toDate(Date.now()), { hours: 1.5 }),
    },
    {
      heading: pastSessionsLabels.this_week_checkbox_label,
      name: '1',
      pastDate: sub(toDate(Date.now()), { days: 1 }),
    },
    {
      heading: pastSessionsLabels.last_week_checkbox_label,
      name: '7',
      pastDate: sub(toDate(Date.now()), { days: 7 }),
    },
    {
      heading: pastSessionsLabels.last_month_checkbox_label,
      name: '28',
      pastDate: sub(toDate(Date.now()), { days: 28 }),
    },
  ];

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  //hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { reqRelationships, isLoadingRelationships } = useRelationships({
    getSilently: false,
  });

  //form
  const { handleSubmit } = useForm<ISessionLogInputs>({ mode: 'onChange' });

  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const guideProfiles = relationship.guideProfiles ?? [];
  const traineeProfiles = relationship.traineeProfiles ?? [];

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  //sessions
  const { reqSessions, isLoadingSessions } = useSessions({});

  const programSlug = relationship.programSlug;
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  if (!program) {
    navigate('/relationships');
    return <></>;
  }

  const programName = program.metadata.program_name;

  const isGroupProgram =
    relationship?.programVariationTypeSlug === EProgramVariation.Group;

  const defaultTitle = `${
    isGroupProgram
      ? relationship?.title
      : `${guideProfiles[0].firstName} / ${traineeProfiles[0].firstName}`
  } - ${programName}`;

  const onSubmit: SubmitHandler<ISessionLogInputs> = async (data) => {
    if (!checkedDate) {
      return;
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions() as {
      timeZone?: TimeZone | string;
    };

    const selectedDate = checkboxes.find(
      (checkbox) => checkbox.name === checkedDate,
    );
    const newStart = selectedDate?.pastDate;
    const endStart = newStart ? add(newStart, { hours: 1 }) : undefined;

    const startUTC =
      timeZone && newStart ? zonedTimeToUtc(newStart, timeZone) : newStart;
    const endUTC =
      timeZone && endStart ? zonedTimeToUtc(endStart, timeZone) : endStart;

    const sessionsResponse = await reqSessions({
      method: 'POST',
      url: `/sessions?organizationSlug=${organizationSlug}`,
      data: {
        name: defaultTitle,
        organizationSlug,
        programSlug,
        relationshipId: relationship.id,
        description: '',
        participantProfiles: [
          ...traineeProfiles.map((trainee) => trainee.id || ''),
          ...guideProfiles.map((guide) => guide.id || ''),
        ],
        start: startUTC,
        end: endUTC,
        timezone: timeZone,
        ownerProfile: profile.id,
        pastSession: true,
      },
    });

    if (sessionsResponse.status !== 'success') {
      dispatch(
        showAppAlert({
          severity: 'error',
          message:
            baseLanguage.relationships.sessions.log_past_session_modal
              .log_session_error_alert_text,
          timeout: 5000,
        }),
      );
    } else {
      await Promise.all([
        reqSessions({ url: '/sessions' }),
        reqRelationships({ url: '/relationships' }),
      ]);

      dispatch(
        showAppAlert({
          severity: 'success',
          message:
            baseLanguage.relationships.sessions.log_past_session_modal
              .log_session_success_alert_text,
          timeout: 5000,
        }),
      );

      handleClose();
    }
  };

  const modalActions: IButtonAction | IButtonAction[] = [
    {
      label: baseLanguage.globals.common.cancel_button_label ?? 'Cancel',
      color: 'info',
      variant: 'outlined',
      action: handleClose,
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsLog_close-button',
    },
    {
      label: baseLanguage.globals.sessions.log_session_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !Boolean(checkedDate),
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsLog_submit-button',
    },
  ];

  return (
    <ModalCard
      title={baseLanguage.relationships.sessions.log_past_session_modal.title}
      description={
        baseLanguage.relationships.sessions.log_past_session_modal.description
      }
      actions={modalActions}
      handleClose={handleClose}
      isLoading={isLoadingSessions() || isLoadingRelationships()}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
        justifyContent={'space-between'}
      >
        <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
          {checkboxes.map((checkbox) => (
            <Checkbox
              key={`${checkbox.name}`}
              heading={checkbox.heading}
              variant="option"
              onChange={() => setCheckedDate(checkbox.name)}
              isChecked={checkedDate === checkbox.name}
            />
          ))}
        </ThemeProvider>
      </Stack>
    </ModalCard>
  );
};
