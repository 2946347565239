import React, { useCallback } from 'react';

import { IProfile, ISession } from '@guider-global/shared-types';
import VideoCameraFront from '@mui/icons-material/VideoCameraFront';
import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import { SiMicrosoftteams } from 'react-icons/si';

// Hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

import {
  getFriendlyDateFormat,
  getISOStringWithoutMilliseconds,
  getLanguageFromCMS,
  getTimeFormat,
} from '@guider-global/front-end-utils';
import { GuideAvatar } from 'components/Guide';
import { useMixpanelEvents } from 'hooks';

export interface IAttendee extends Partial<IProfile> {
  userPicture?: string;
}

interface ISessionsDetails {
  session: ISession;
  attendees: IAttendee[];
}

export const SessionsDetails: React.FC<ISessionsDetails> = ({
  session,
  attendees,
}) => {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const { trackScreenTime } = useMixpanelEvents();

  const trackSessionJoinButtonClick = useCallback(() => {
    trackScreenTime(
      'Relationship - Session - Session viewed - Join call button',
      { Started: getISOStringWithoutMilliseconds() },
    );
  }, [trackScreenTime]);

  if (!session?.createdAt) {
    return <></>;
  }

  const locale = getLanguageFromCMS(baseLanguage.language_code);

  const formattedTime = getTimeFormat(baseLanguage.time_format);
  const formattedDate = getFriendlyDateFormat(baseLanguage.date_format);

  const renderAttendees = () =>
    attendees.map((profile, index) => (
      <Box
        key={`${profile.id}`}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mt: index !== 0 ? 1.5 : 0,
        }}
      >
        <GuideAvatar
          width={24}
          height={24}
          profilePicture={profile.picture}
          userPicture={profile.userPicture}
          sx={{ mr: 1 }}
        />
        <Typography variant="body2">{profile.displayName}</Typography>
      </Box>
    ));

  const renderJoinSessionButton = () => {
    switch (session.videoConferencing) {
      case 'guider':
        return (
          <Button
            data-cy="components_Sessions_SessionsDetails_video-button"
            component="a"
            variant="outlined"
            color="info"
            size="small"
            startIcon={<VideoCameraFront />}
            sx={{ backgroundColor: 'white', fontWeight: 600, mt: 1.5 }}
            fullWidth
            onClick={() => {
              trackSessionJoinButtonClick();
              window.open(
                `${
                  window.location.protocol + '//' + window.location.host
                }/video/${session.id}`,
              );
            }}
          >
            {
              baseLanguage.relationships.sessions.view_session_modal
                .join_with_guider_video_button_label
            }
          </Button>
        );
      case 'ms-teams':
        return (
          <Button
            data-cy="components_Sessions_SessionsDetails_video-button"
            component="a"
            variant="outlined"
            color="info"
            size="small"
            startIcon={<SiMicrosoftteams />}
            sx={{ backgroundColor: 'white', fontWeight: 600, mt: 1.5 }}
            fullWidth
            href={session.videoConferencingUrl}
            target="_blank"
            onClick={trackSessionJoinButtonClick}
          >
            {
              baseLanguage.relationships.sessions.view_session_modal
                .join_with_ms_teams_button_label
            }
          </Button>
        );
      default:
        return;
    }
  };

  return (
    <>
      <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
        {session.name}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 600, mt: 2 }}>
        {baseLanguage.relationships.sessions.view_session_modal.when_label}
      </Typography>
      <Typography variant="body2">
        {format(new Date(session.start), `${formattedDate}`, {
          locale,
        })}
      </Typography>
      <Typography variant="body2" sx={{ mt: 0.75 }}>
        {format(new Date(session.start), `${formattedTime}`, {
          locale,
        })}
        {' - '}
        {format(new Date(session.end), formattedTime, { locale })}
      </Typography>
      {session.location && (
        <>
          <Typography variant="body1" sx={{ fontWeight: 600, mt: 2 }}>
            {baseLanguage.relationships.sessions.view_session_modal.where_label}
          </Typography>
          <Typography variant="body2">{session.location}</Typography>
        </>
      )}
      {renderJoinSessionButton()}
      <Typography variant="body1" sx={{ fontWeight: 600, mt: 2, mb: 1.5 }}>
        {baseLanguage.relationships.sessions.view_session_modal.attendees_label}
      </Typography>
      {renderAttendees()}
    </>
  );
};
