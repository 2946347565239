// external
import { FC } from 'react';
import { Skeleton, Typography } from '@mui/material';

export interface SettingsSubheaderProps {
  subheader: string;
}

export const SettingsSubheader: FC<SettingsSubheaderProps> = ({
  subheader,
}) => {
  if (!subheader)
    return (
      <>
        <Skeleton variant="text">
          <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
            Empty subheader
          </Typography>
        </Skeleton>
      </>
    );
  return (
    <>
      <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
        {subheader}
      </Typography>
    </>
  );
};
