// external
import React, { useEffect } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';

// components
import { RelationshipsList } from 'components';

// Hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

// types
import { IRelationship } from '@guider-global/shared-types';
import { useParams } from 'react-router-dom';

interface IRelationshipsArchivedListProps {
  relationships: IRelationship[];
  isLoading: boolean;
}

export const RelationshipsArchivedList: React.FC<
  IRelationshipsArchivedListProps
> = ({ relationships, isLoading }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const label =
    baseLanguage.relationships.relationships_list.archived_section_label;

  const { relationshipId } = useParams();

  const relationshipIds = relationships.map((relationship) => relationship.id);

  const isActive = relationshipId
    ? relationshipIds.includes(relationshipId)
    : false;

  useEffect(() => {
    if (isActive) {
      setExpanded(true);
    }
  }, [isActive]);

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) =>
    setExpanded(expanded);

  const accordionProps = {
    elevation: 0,

    sx: {
      width: '100%',
      backgroundColor: 'transparent',
      ':before': { display: 'none' },
    },
  };

  const accordionSummaryProps = {
    expandIcon: <ArrowDropDown />,
    'aria-controls': 'panel1a-content',
    id: 'panel1a-header',
    sx: {
      m: 0,
      p: 0,
      minHeight: 'auto',
      '& .MuiAccordionSummary-content': {
        m: 0,
        '&.Mui-expanded': {
          m: 0,
          minHeight: 'auto',
        },
      },
      '&.Mui-expanded': {
        m: 0,
        minHeight: 'auto',
      },
    },
  };

  const accordionDetailsProps = {
    sx: {
      p: 0,
    },
  };

  return (
    <Accordion {...accordionProps} expanded={expanded} onChange={handleChange}>
      <AccordionSummary {...accordionSummaryProps}>
        <Typography
          variant="overline"
          sx={{ letterSpacing: 1 }}
          color="text.disabled"
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        {...accordionDetailsProps}
        sx={{ userSelect: 'none', p: 0 }}
      >
        <RelationshipsList
          relationships={relationships}
          isLoading={isLoading}
        />
      </AccordionDetails>
    </Accordion>
  );
};
