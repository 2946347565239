// external
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import React, { useEffect } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// internal
import {
  getISOStringWithoutMilliseconds,
  getSubDomain,
} from '@guider-global/front-end-utils';

// components
import { SessionsCreateForm } from 'forms';

// store
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

// hooks
import {
  useMixpanelEvents,
  useProfiles,
  useRelationships,
  useSessions,
} from 'hooks';

// types
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  IRelationship,
  ISession,
  ISessionVideoConferencing,
  TimeZone,
} from '@guider-global/shared-types';
import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';
import { getVideoConferencingProvider } from 'utils/getVideoConferencingProvider';

export interface ISessionInputs {
  title: string;
  date: Date;
  startTime: string;
  endTime: string;
  location: string;
  videoConferencing: ISessionVideoConferencing;
}

interface IRelationshipSessionsCreateProps {
  relationship: IRelationship;
  handleClose: () => void;
}

export const RelationshipSessionsCreate: React.FC<
  IRelationshipSessionsCreateProps
> = ({ relationship, handleClose }) => {
  //baseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  //hooks
  const { trackScreenTime } = useMixpanelEvents();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { reqRelationships, isLoadingRelationships } = useRelationships({
    getSilently: false,
  });

  //form
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm<ISessionInputs>({ mode: 'onChange' });
  const typedControl: Control = control as unknown as Control;

  //profiles
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const guideProfiles = relationship.guideProfiles ?? [];
  const traineeProfiles = relationship.traineeProfiles ?? [];

  //sessions
  const { reqSessions, isLoadingSessions } = useSessions({});

  const currentSessions = relationship.sessions as Partial<ISession>[];

  const programSlug = relationship.programSlug;
  const { getProgram, isLoadingSanityPrograms } = useSanityOrganizationPrograms(
    {},
  );
  const program = getProgram(programSlug);
  const loadingPrograms = isLoadingSanityPrograms();

  useEffect(() => {
    if (!program && !loadingPrograms) {
      navigate('/relationships');
    } else {
      trackScreenTime(
        'Relationship - Session - Schedule new session - Form viewed',
        {
          Started: getISOStringWithoutMilliseconds(),
        },
      );
    }
  }, [trackScreenTime, navigate, program, loadingPrograms]);

  useEffect(() => {
    if (!program) return;
    trackScreenTime(
      'Relationship - Session - Schedule new session - Form viewed',
      { Started: getISOStringWithoutMilliseconds() },
    );
  }, [program, trackScreenTime]);

  if (!program) {
    return <></>;
  }

  //handlers
  const programName = program.metadata.program_name;

  const isGroupProgram =
    relationship?.programVariationTypeSlug === EProgramVariation.Group;

  const defaultTitle = `${
    isGroupProgram
      ? relationship?.title
      : `${guideProfiles[0].firstName} / ${traineeProfiles[0].firstName}`
  } - ${programName}`;

  const trackSessionCreateFormSubmit = (
    videoConferencing: ISessionVideoConferencing,
    location: any,
  ) => {
    trackScreenTime(
      'Relationship - Session - Schedule new session - Form submitted',
      {
        'Session count': currentSessions.length + 1,
        'Video conferencing': Boolean(videoConferencing),
        'Video conferencing provider':
          getVideoConferencingProvider(videoConferencing),
        Location: Boolean(location),
      },
    );
  };

  const onSubmit: SubmitHandler<ISessionInputs> = async (data) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions() as {
      timeZone?: TimeZone | string;
    };

    const { date, startTime, endTime, videoConferencing, location } = data;

    const [startHours, startMinutes] = startTime.split(':');
    const newStart = toDate(date);
    newStart.setHours(parseInt(startHours), parseInt(startMinutes));

    const [endHours, endMinutes] = endTime.split(':');
    const newEnd = toDate(date);
    newEnd.setHours(parseInt(endHours), parseInt(endMinutes));

    const startUTC = timeZone ? zonedTimeToUtc(newStart, timeZone) : newStart;
    const endUTC = timeZone ? zonedTimeToUtc(newEnd, timeZone) : newEnd;

    const sessionsResponse = await reqSessions({
      method: 'POST',
      url: `/sessions?organizationSlug=${organizationSlug}`,
      data: {
        name: data.title,
        organizationSlug,
        programSlug,
        relationshipId: relationship.id,
        description: '',
        participantProfiles: [
          ...traineeProfiles.map((trainee) => trainee.id ?? ''),
          ...guideProfiles.map((guide) => guide.id ?? ''),
        ],
        start: startUTC,
        end: endUTC,
        timezone: timeZone,
        hasVideoConferencing: Boolean(videoConferencing),
        videoConferencing: videoConferencing,
        ownerProfile: profile.id,
        location,
      },
    });

    if (sessionsResponse.status !== 'success') {
      dispatch(
        showAppAlert({
          severity: 'error',
          message:
            baseLanguage.relationships.sessions.new_session_modal
              .error_alert_text,
          timeout: 5000,
        }),
      );
    } else {
      trackSessionCreateFormSubmit(videoConferencing, location);

      await Promise.all([
        reqSessions({ url: '/sessions' }),
        reqRelationships({ url: '/relationships' }),
      ]);

      dispatch(
        showAppAlert({
          severity: 'success',
          message:
            baseLanguage.relationships.sessions.new_session_modal
              .success_alert_text,
          timeout: 5000,
        }),
      );

      handleClose();
    }
  };

  const modalActions: IButtonAction | IButtonAction[] = [
    {
      label: baseLanguage.globals.common.cancel_button_label ?? 'Cancel',
      color: 'info',
      variant: 'outlined',
      action: handleClose,
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsCreate_close-button',
    },
    {
      label:
        baseLanguage.relationships.sessions.new_session_modal
          .send_calendar_invite_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !isValid,
      dataCyLabel:
        'components_Relationships_RelationshipSessions_RelationshipSessionsCreate_submit-button',
    },
  ];

  return (
    <ModalCard
      title={baseLanguage.relationships.sessions.new_session_modal.title}
      description={
        baseLanguage.relationships.sessions.new_session_modal.subtitle
      }
      handleClose={handleClose}
      actions={modalActions}
      isLoading={isLoadingSessions() || isLoadingRelationships()}
    >
      <SessionsCreateForm
        handleSubmit={handleSubmit(onSubmit)}
        control={typedControl}
        errors={errors}
        isValid={isValid}
        isLoadingSessions={false}
        discardAction={() => navigate(-1)}
        setValue={setValue}
        defaultTitle={defaultTitle}
        hideButtons
      />
    </ModalCard>
  );
};
