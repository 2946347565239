// external
import { Box, Collapse } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components
import {
  Chat,
  RelationshipDetailsGroupSidebar,
  RelationshipDetailsSidebar,
  RelationshipHeader,
} from 'components';

// store
import { RootState } from 'store';
import { hideNavbar, showNavbar } from 'store/slices/appSlice';

// hooks
import { useMixpanelEvents, useMobileMediaQuery, useProfiles } from 'hooks';

// types
import { getISOStringWithoutMilliseconds } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  IProfile,
  IRelationship,
} from '@guider-global/shared-types';
import { interpolate } from 'functions';
import { RelationshipGroupTraineeModal } from 'modals/Relationship/RelationshipGroup';
import { getRecipients } from 'utils/getRelationshipRecipients';

interface IRelationshipDetailsProps {
  relationship: IRelationship;
}

export const RelationshipDetails: React.FC<IRelationshipDetailsProps> = ({
  relationship,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const traineeModal = useSelector(
    (state: RootState) => state.app.modals.traineeModal,
  );

  const programSlug = relationship.programSlug;

  // Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const { trackMixpanelEvent } = useMixpanelEvents();

  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const profileId = profile.id;
  const userId = profile.userId;

  const isMobile = useMobileMediaQuery();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (isMobile) {
      dispatch(hideNavbar(true));
    } else {
      dispatch(showNavbar(true));
    }

    return function cleanup() {
      dispatch(showNavbar(true));
    };
  }, [dispatch, isMobile]);

  const guideProfiles = relationship?.guideProfiles as Partial<IProfile>[];
  const traineeProfiles = relationship?.traineeProfiles as Partial<IProfile>[];

  const guideProfilesIds = guideProfiles?.map(
    (guideProfile) => guideProfile?.id,
  );
  const traineeProfilesIds = traineeProfiles?.map(
    (traineeProfile) => traineeProfile?.id,
  );

  const isGuide = guideProfilesIds?.includes(profileId) ?? false;
  const isTrainee = traineeProfilesIds?.includes(profileId) ?? false;

  const chosenGuide = guideProfiles?.at(0);

  const isGroupProgram =
    relationship?.programVariationTypeSlug === EProgramVariation.Group;
  const guideMembership = relationship?.guideMemberships?.at(0);
  const isNotLiveGroup = guideMembership?.isPublished === true;

  const isTraineeModalOpen = isTrainee && traineeModal;

  // - Programs
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  const relationshipCreatedAt = relationship.createdAt;

  const recipients = getRecipients({
    role: isGuide ? 'guide' : 'trainee',
    relationship,
    userId,
  });

  const handleMessageSend = useCallback(async () => {
    trackMixpanelEvent('Relationship - Message sent', {
      'Sent on': getISOStringWithoutMilliseconds(),
      Role: isGuide ? 'guide' : 'trainee',
      Program: relationship.programSlug,
    });
  }, [isGuide, relationship.programSlug, trackMixpanelEvent]);

  useEffect(() => {
    if (!program || !recipients) return;
    trackMixpanelEvent('Relationship - Viewed', {
      'Viewed on': getISOStringWithoutMilliseconds(),
      Program: relationship.programSlug,
    });
  }, [program, recipients, relationship.programSlug, trackMixpanelEvent]);

  if (!program || !recipients) {
    navigate('/relationships');
    return <></>;
  }

  const programType = program.program_details?.program_type;
  const programVariation =
    programType?.program_type_text?.variations?.individual;

  const programName = program.metadata.program_name;

  const recipient = recipients.at(0);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <RelationshipHeader
        relationship={relationship}
        otherPartyProfile={
          recipient
            ? {
                displayName: recipient.displayName,
                profilePicture: recipient.picture,
                userPicture: recipient.userPicture,
              }
            : undefined
        }
        relationshipCreatedAt={relationshipCreatedAt}
        programName={programName}
        roleLabel={
          (isGuide
            ? programVariation?.relationships?.your_trainee
            : programVariation?.relationships?.your_guide) ?? ''
        }
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          alignContent: 'stretch',
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Collapse
          in={isMobile ? !isSidebarOpen : true}
          sx={{
            width: '100%',
            '& .MuiCollapse-wrapper': {
              width: '100%',
              height: '100%',
            },
            '& .MuiCollapse-wrapperInner': {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'stretch',
              width: '100%',
              height: '100%',
              py: 1,
              px: 2,
              '& .ms-Stack': {
                maxWidth: 'none',
              },
              '& .ui-chat__item__gutter': {
                display: 'none',
              },
              // TODO Can be removed when disableEditing fix has been released by ACS https://github.com/Azure/communication-ui-library/issues/2569
              '& .ui-chat__item__message': {
                '& .ui-chat__message__actions': {
                  ...(relationship.isConcluded && { display: 'none' }),
                },
              },
            }}
          >
            {!!relationship && (
              <Chat
                relationship={relationship}
                isGuide={isGuide}
                isGroupProgram={isGroupProgram}
                isNotLiveGroup={isNotLiveGroup}
                threadId={relationship.threadId}
                archived={relationship.isConcluded}
                onSendMessage={handleMessageSend}
              />
            )}
          </Box>
        </Collapse>
        <Collapse
          in={isMobile ? isSidebarOpen : true}
          sx={{
            width: 'max-content',
            '& .MuiCollapse-wrapper': {
              width: 'max-content',
              height: '100%',
            },
            '& .MuiCollapse-wrapperInner': {
              width: 'max-content',
              height: '100%',
            },
          }}
        >
          {isGroupProgram ? (
            <RelationshipDetailsGroupSidebar
              relationship={relationship}
              isGroupProgram={isGroupProgram}
              isGuide={isGuide}
            />
          ) : (
            <RelationshipDetailsSidebar
              relationship={relationship}
              isGroupProgram={isGroupProgram}
              isGuide={isGuide}
            />
          )}
        </Collapse>
      </Box>
      {!isGuide && (
        <RelationshipGroupTraineeModal
          isModalOpen={isTraineeModalOpen}
          title={
            interpolate(
              programType?.program_type_text.variations?.group?.registration
                ?.registration_trainee
                ?.registration_trainee_relationship_created_successfully
                ?.registration_trainee_relationship_created_successfully_title ??
                '',
              {
                groupName: relationship?.title ?? '',
              },
            ) ?? ''
          }
          description={
            interpolate(
              programType?.program_type_text.variations?.group?.registration
                ?.registration_trainee
                ?.registration_trainee_relationship_created_successfully
                ?.registration_trainee_relationship_created_successfully_description ??
                '',
              {
                firstName: profile.firstName ?? '',
              },
            ) ?? ''
          }
          buttonLabel={
            baseLanguage.globals.relationships.view_relationship ??
            'View Relationship'
          }
          picture={chosenGuide?.picture ?? ''}
        />
      )}
    </Box>
  );
};

export default RelationshipDetails;
