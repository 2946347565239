//external
import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';

//types
import { IRelationship } from '@guider-global/shared-types';

//components
import { ActionButton } from 'components';

//hooks

import { getSubDomain } from '@guider-global/front-end-utils';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { IButtonAction } from 'components/ActionButton';
import { NotesCreateForm } from 'forms';
import {
  useMobileMediaQuery,
  useNotes,
  useRelationships,
  useUsers,
} from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { INoteInputs } from '../RelationshipNotesCreate';
import { ConfirmationModal } from 'modals';
import { format, toDate } from 'date-fns-tz';

interface IRelationshipNotesView {
  relationship: IRelationship;
}

export const RelationshipNotesView: React.FC<IRelationshipNotesView> = ({
  relationship,
}) => {
  const { users } = useUsers({ getSilently: false });
  const user = users().at(0);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  //hooks
  const navigate = useNavigate();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const cancelButtonLabel =
    baseLanguage.globals?.common?.cancel_button_label ?? 'Cancel';

  const params = useParams();

  const currentNote = useMemo(() => {
    const relationshipNotes = relationship?.notes ?? [];
    return relationshipNotes.find((note) => note.id === params.noteId);
  }, [params.noteId, relationship?.notes]);

  const organizationSlug = getSubDomain();
  const { isLoadingNotes, reqNotes } = useNotes({
    getSilentlyUrl: `/notes?organizationSlug=${organizationSlug}`,
  });

  const { isLoadingRelationships, reqRelationships } = useRelationships({
    getSilently: false,
  });

  const isLoading = isLoadingRelationships() || isLoadingNotes();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<INoteInputs>({ mode: 'onChange' });
  const typedControl = control as unknown as Control;

  //style
  const isMobile = useMobileMediaQuery();

  const guideProfiles = relationship.guideProfiles ?? [];
  const traineeProfiles = relationship.traineeProfiles ?? [];

  const profiles = [...guideProfiles, ...traineeProfiles];

  const ownersProfile = profiles.find(
    (guideProfile) => guideProfile.userId === currentNote?.ownerId,
  );

  const lastUpdatedByProfile = profiles.find(
    (profile) => profile.userId === currentNote?.lastUpdatedBy,
  );

  const currentNoteUpdateAt =
    currentNote?.updatedAt &&
    format(toDate(currentNote.updatedAt), 'MMMM d yyyy');

  const currentNoteCreatedAt =
    currentNote?.createdAt &&
    format(toDate(currentNote.createdAt), 'MMMM d yyyy');

  const lastUpdatedByString =
    currentNote &&
    `${baseLanguage.relationships.notes.notes_modal.notes_modal_create_edit_note.note_status.note_status_last_updated_by_label} ${lastUpdatedByProfile?.firstName} ${lastUpdatedByProfile?.lastName} ${baseLanguage.relationships.notes.notes_modal.notes_modal_create_edit_note.note_status.note_status_last_updated_on_label} ${currentNoteUpdateAt}`;

  const createdByString =
    currentNote &&
    `${baseLanguage.relationships.notes.notes_modal.notes_modal_create_edit_note.note_status.note_status_created_by_label} ${ownersProfile?.firstName} ${ownersProfile?.lastName} ${baseLanguage.relationships.notes.notes_modal.notes_modal_create_edit_note.note_status.note_status_created_on_label} ${currentNoteCreatedAt}`;

  const isNoteOwner = currentNote?.ownerId === user?.id;

  const onSubmit: SubmitHandler<INoteInputs> = async (data) => {
    const { isPublic, ...rest } = data;

    await reqNotes({
      method: 'PATCH',
      url: `/notes/${currentNote?.id}`,
      data: {
        ...rest,
        isPrivate: !isPublic,
      },
    });

    await reqRelationships({ url: '/relationships' });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteNote = async () => {
    await reqNotes({
      method: 'DELETE',
      url: `/notes/${currentNote?.id}?organizationSlug=${organizationSlug}`,
    });

    await reqRelationships({ url: '/relationships' });

    navigate(`/relationships/${relationship.id}/notes`);
  };

  const actions: IButtonAction[] = [
    ...(isNoteOwner
      ? [
          {
            label: baseLanguage.globals.common.delete_button_label,
            variant: 'text',
            color: 'error',
            action: () => setIsDeleteModalOpen(true),
            isLoadingButton: true,
            dataCyLabel:
              'components_Relationships_RelationshipNotes_RelationshipNotesCreate_delete-button',
          } as IButtonAction,
        ]
      : []),
    {
      label:
        baseLanguage.relationships.notes.notes_modal
          .notes_modal_create_edit_note.save_note_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !isDirty,
      dataCyLabel:
        'components_Relationships_RelationshipNotes_RelationshipNotesCreate_submit-button',
    },
  ];

  const deleteModalActions: IButtonAction[] = [
    {
      label: cancelButtonLabel,
      action: handleDeleteModalClose,
      color: 'info',
      variant: 'outlined',
      dataCyLabel:
        'components_Relationships_RelationshipNotes_RelationshipNotesView_discard-delete-button',
    },
    {
      label: baseLanguage.globals.common.delete_button_label,
      action: handleDeleteNote,
      color: 'error',
      variant: 'contained',
      isLoadingButton: true,
      dataCyLabel:
        'components_Relationships_RelationshipNotes_RelationshipNotesView_confirm-delete-button',
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        p: 2,
        pt: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <NotesCreateForm
          handleSubmit={handleSubmit(onSubmit)}
          control={typedControl}
          errors={errors}
          lastUpdatedBy={lastUpdatedByString}
          createdBy={createdByString}
          reset={reset}
          note={currentNote}
        />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isNoteOwner ? 'space-between' : 'flex-end',
          }}
        >
          {actions.map((action) => (
            <ActionButton
              isLoading={isLoading}
              buttonAction={action}
              key={`modal-button-${action.label}`}
            />
          ))}
        </Box>
      </Box>

      <ConfirmationModal
        handleClose={handleDeleteModalClose}
        open={isDeleteModalOpen}
        title={
          baseLanguage.relationships.notes.notes_modal
            .notes_modal_create_edit_note.delete_note_modal
            .discard_changes_title
        }
        description={
          baseLanguage.relationships.notes.notes_modal
            .notes_modal_create_edit_note.delete_note_modal
            .discard_changes_description
        }
        actions={deleteModalActions}
        isLoading={isLoading}
      />
    </Box>
  );
};
